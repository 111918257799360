<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nuevo Fallo</h1>
        <hr class="">
      </v-col>
      <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
        <v-row class="mb-4">
          <v-expansion-panels inset>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Descripcion</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Descripcion</h1>
                  <trumbowyg
                      v-model="data.descripcion"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Fecha y Hora"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Antecedentes</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Antecedentes
                  </h1>
                  <trumbowyg
                      v-model="data.antecedentes"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Antecedentes"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Considerado</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Considerado
                  </h1>
                  <trumbowyg
                      v-model="data.considerando"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Considerado"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Resolutivo</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Resolutivo
                  </h1>
                  <trumbowyg
                      v-model="data.resolutivo"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Resolutivo"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Hora</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Hora
                  </h1>
                  <trumbowyg
                      v-model="data.hora"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Hora"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Pie representante</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Pie representante
                  </h1>
                  <trumbowyg
                      v-model="data.pie_representantes"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Pie representante"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-col cols="12" class="mt-4">
            <v-btn type="submit" color="primary" style="width: 200px">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
      data: {
        tenderId: null,
        formatId: null,
        descripcion: "",
        antecedentes: "",
        representante: '',
        considerando: '',
        resolutivo: '',
        hora: '',
        pie_representantes: '',
      },
    };
  },
  components: {
    Overlay,
    Trumbowyg
  },
  created() {
    this.data.tenderId = this.$route.params.id;
    this.data.formatId = this.$route.params.formatId;

  },
  methods: {
    ...mapActions('rulings', ['createRulings']),
    async validateRequired(){
      if (!this.data.descripcion){
        this.sweetAlertWarning('Campo descripcion es necesario');
        return false
      }

      if (!this.data.antecedentes){
        this.sweetAlertWarning('Campo Antecedentes es requerido');
        return false
      }
      if (!this.data.considerando){
        this.sweetAlertWarning('Campo Considerando es requerido');
        return false
      }
      if (!this.data.resolutivo){
        this.sweetAlertWarning('Campo Resolutivo es requerido');
        return false
      }
      if (!this.data.hora){
        this.sweetAlertWarning('Campo Hora es requerido');
        return false
      }
      if (!this.data.pie_representantes){
        this.sweetAlertWarning('Campo Pie de Representante es requerido');
        return false
      }
      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createRulings(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
